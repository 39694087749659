<template lang="pug">
  div.rocky-list.m-auto
    .text-center
      b-spinner.mt-5.mb-5(variant="primary" v-if="isLoading")
    .container(v-if="!isLoading")
      b-button.button-blue#search-conditions-change.mt-5(@click="callRouterPush('/rocky/search')") 検索条件を変更する
      div.my-4(v-if="falseInfo") 情報の取得に失敗しました
      div(v-if="!falseInfo")
        #title
          h5 岩場の検索結果 全{{dataCount}}件
        ul.list-group.list-group-flush
          li.list-group-item.rocky-card(@click="onClick(data.rockyId)" v-for="data in rockyListJson")
            .row
              div
                img.rounded.rocky-img(:src="makeImgPath(data.imgPath)")
              .col
                div.small {{data.rockyNameKana}}
                h6 {{data.rockyName}}
                div.small {{data.address}}
</template>

<script>
import Loading from '@/components/Loading.vue'
import axios from 'axios'

export default {
  // データオブジェクト
  data() {
    return {
      // ログイン済みかどうか
      isLogin: this.$user.isLogin,
      rockyListJson: null,
      dataCount: null,
      isLoading:true,
      falseInfo: false,
    }
  },
  // インスタンス作成後
  created() {
    this.createdProcess()
  },
  //rocky/rocky_list画面の検索条件変更モーダルを使用した場合、
  //同一パス(パラメータのみ変更）となりコンポーネント再利用され
  //this.$router.pushしても再読み込みされない仕様である。
  //その為、watchでクエリストリングの変更をフックして、
  //$router.goを使用して強制的に再読み込みする。
  watch: {
    '$route' (to, from) {
      // ルートの変更の検知
      this.$router.go({path: to, force: true});
    }
  },
  // メソッド
  methods: {
    exec(e) {
      this.message = this.msg
    },
    onClick(rockyId){
      this.$router.push('/rockyInfo/' + rockyId)
    },
    async createdProcess(){

      //岩場情報Listを取得
      await this.getRockyList() 
      this.isLoading = false
    },
    async getRockyList() {
      const jsonUrl = process.env.VUE_APP_API_GATEWAY + '/api-search-rocky/rocky-list'

      await axios.get(jsonUrl,{
        params: {
          "style" : this.$route.query.style,
          "regiones" : this.$route.query.regiones,          
          "keyword" : this.$route.query.keyword
        },
        headers: {}
      }).then(response => {
        this.rockyListJson = response.data
        this.dataCount = response.data.length
      })
      .catch(err => {
        this.falseInfo = true
      })

      //データが0件の場合
      if(this.dataCount == 0){
        await this.asyncDialog()
      }
    },
    asyncDialog() {
      return new Promise((resolve) => {
        this.$bvModal.msgBoxOk('該当するデータが存在しません。検索条件を変更して下さい。', {
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'success',
          headerClass: 'p-2 border-bottom-0',
          footerClass: 'p-2 border-top-0',
          centered: true
        })
        .then(value => {
          resolve(value)
        })
        .catch(err => {
          // An error occurred
        })
      })
    },
    makeImgPath(imgPath){
      return this.getRockyThumbPath(imgPath,'120')
    },
    callRouterPush(path){
      this.$router.push(path)
    }
  },
  // コンポーネント
  components: {
    Loading,
  }
}
</script>

<style lang="stylus" scoped>
.rocky-img
  max-width 120px
  height auto
.rocky-card:hover
  cursor pointer
.rocky-list
  max-width 720px

//コンテナ群
#title
  padding-top 2rem
</style>